// Typography

// Note: we want to use default fonts in the actual code examples and documentation.

blockquote {
  margin: 1rem 0;
  border-left: 4px solid #ccc;
  padding: 1rem;
  padding-right: 0.5rem;
  background: #eee;
}

.wsg-content {
  h1, h2, h3, h4, h5, h6 {
    font-family: $wsg-sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    margin-top: 2rem;
  }
  //h2 {
  //  padding-top: 35px;
  //  padding-bottom: 10px;
  //}
  //
  //h3 {
  //  padding-top: 25px;
  //  padding-bottom: 10px;
  //}
  //
  //p {
  //  padding-top: 10px;
  //  padding-bottom: 10px;
  //  margin-top: 1rem;
  //  margin-bottom: 1rem;
  //}
}


.wsg-title,
.wsg-page-header h1 {
  font-family: $wsg-sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;

}

//.btn, label, input, .form-text, th, td, .alert, .sans-serif {
//  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, arial, sans-serif;
//  font-size: 16px;
//}

body, .serif {
  font-family: $wsg-serif;
}


.sans-serif, .btn, .nav-link, .dropdown-item, .admin-sidenav {
  font-family: $wsg-sans-serif;
}


.chroma, code, kbd, pre, samp {
  font-family: $wsg-monospace;
}

.bd-example {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  h1, h2, h3, h4, h5, h6 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
  }
}

// Use Bootstrap defaults in code examples
.wsg-example {
  font-family: $font-family-sans-serif;
  .sans-serif, .btn, .nav-link, .dropdown-item {
    font-family: $font-family-sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  code, kbd, pre, samp {
    font-family: $font-family-monospace;
  }
}



