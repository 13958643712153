  /** Button Stuff **/

.btn-primary, .btn-primary:hover, .btn-outline-primary:hover {
  color: #fff;
}

.btn-clipboard {
  @extend .btn-primary;
  //transition: all .2s ease-in-out;
  //  &:hover {
  //    box-shadow:inset .1rem .1rem 0.5rem #4695d6,
  //    inset -.2rem -.2rem 1rem #72bbf7;
  //  }
}

.wsg-btn {
  border-radius: 0.375rem;

}

.get-started-btn {
  transition: all .2s ease-in-out;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
  .get-started-btn-text {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    font-family:  $wsg-sans-serif;
    letter-spacing: .20px;
    text-decoration: none;
    padding-left: 50px;
    padding-right: 50px;
  }
  .get-started-btn-text:hover {
    color: #fff;
    font-weight: 500;
    font-family: $wsg-sans-serif;
    letter-spacing: .20px;
    text-decoration: none;
  }
}

.learn-more-btn {
  border-color: transparent!important;
  transition: all .2s ease-in-out;
    &:hover, :focus {
      box-shadow:inset .1rem .1rem 0.5rem #4695d6,
      inset -.2rem -.2rem 1rem #72bbf7;
    }

    .learn-more-btn-text {
      color: #fff;
      font-weight: 500;
      font-family:  $wsg-sans-serif;
      letter-spacing: 1px;
      text-decoration: none;
    }
    .learn-more-btn-text:hover {
      color: #fff;
      font-family: $wsg-sans-serif;
      letter-spacing: 1px;
      text-decoration: none;
    }
  }


.wsg-btn-primary {
  color: #fff;
  font-weight: 400;
  font-family:  $wsg-sans-serif;
  letter-spacing: 1px;
  text-decoration: none;
  border-color: transparent!important;
  transition: all .2s ease-in-out;
    &:hover, :focus {
      background-color: darken($wsg-blue-alt, 10%);
      box-shadow:inset .1rem .1rem 0.5rem darken($wsg-blue-alt, 5%),
      inset -.1rem -.1rem 0.5rem darken($wsg-blue-alt, 15%);
    }
  }

.wsg-btn-outline-primary {
  border-color: $wsg-blue-alt;
  color: $webstop-blue;
  &:hover, &:focus {
    background-color: darken($wsg-blue-alt, 10%);
    border-color: $webstop-blue;
    box-shadow:inset .1rem .1rem 0.5rem #4695d6,
    inset -.2rem -.2rem 1rem #72bbf7;
  }
}

@keyframes gridButton {
  0% {
    transform: scale(1, 1) rotateZ(0deg);
  }
  50% {
    transform: scale(1.1, 1.1) rotateZ(90deg);
  }
  100% {
    transform: scale(1, 1) rotateZ(180deg);
  }
}

.wsg-grid-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  width: 6rem;
  height: 6rem;
  white-space: nowrap;
  background: #fff;
  border: 0.5rem solid #777;
  color:$webstop-blue!important;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3rem;
}
.wsg-grid-button.processing {
  animation-name: gridButton;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.wsg-grid-button:focus {
  outline: 0;
  border: 0.7rem double #000;
  padding: 1rem;
}

/** End Button Stuff **/
