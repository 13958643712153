.wsg-header {
  background-color: $wsg-dark!important;
}

.wsg-title-bg {
  background-color: $webstop-blue;
}

// Background Gradient
.wsg-title-bg-gradient {
  background: rgb(0,179,255);
  background: linear-gradient(90deg, rgba(0,179,255,1) 0%, rgba(19,185,255,1) 50%, rgba(0,179,255,1) 100%);
}

.wsg-inner-shadow {
  -moz-box-shadow:    inset 0 -7px 7px -7px #777;
  -webkit-box-shadow: inset 0 -7px 7px -7px #777;
  box-shadow:         inset 0 -7px 7px -7px #777;

}

.wsg-page-title {
  font-weight: 900;
  font-size: 50px;
  border-radius: 15px;
  box-shadow:  5px  5px 5px #777777,
              -5px -5px 5px #ffffff;
}



.docs-heading {
  margin: -$spacer;
  margin-bottom: $spacer;
  padding: $spacer;
  background: $wsg-blue-alt;


  .docs-heading-title {
    margin: $spacer auto;
    padding: $spacer;
    max-width: 700px;
    text-align: center;
    background: #fff;
    border-radius: $spacer;
  }

  .docs-heading-tagline {
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
  }
}



