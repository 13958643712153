// Extend Bootstrap Buttons

// We keep this outside .btn because we want it to be easily overridden.
[class^='btn-outline-'], [class*=' btn-outline-']{
  background-color: $light;
}

.btn {
  &.btn-plain {
    background: transparent;
    padding: 0;
    border: 0;
  }
  &.bg-primary-subtle:hover {
    background: $primary !important;
  }
  &.bg-secondary-subtle:hover {
    background: $secondary !important;
  }
  &.bg-success-subtle:hover {
    background: $success !important;
  }
  &.bg-info-subtle:hover {
    background: $info !important;
  }
  &.bg-warning-subtle:hover {
    background: $warning !important;
  }
  &.bg-danger-subtle:hover {
    background: $danger !important;
  }
  &.bg-light-subtle:hover {
    background: $light !important;
  }
  &.bg-dark-subtle:hover {
    background: $dark !important;
  }
}
