/** Examples and Highlights **/

.highlight {
  margin-top: $spacer;
  margin-bottom: $spacer;
  position: relative;
  white-space:nowrap;
  overflow: hidden;
  overflow-y: auto;
}

.wsg-clipboard {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.wsg-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

.wsg-example-container-header {
  height: 3rem;
  margin-bottom: .5rem;
  background-color: lighten($wsg-primary, 25%);
  border-radius: 0.75rem;
}

.wsg-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: lighten($wsg-primary, 50%);
  border-radius: 0.75rem;
}

.wsg-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: lighten(purple, 25%);
  border-radius: 0.75rem;
}

.wsg-example-container-fluid {
  max-width: none;
}

  .example {
    font-family:  // Cross-platform generic font family (default user interface font)
    system-ui,
    // Safari for macOS and iOS (San Francisco)
    -apple-system,
    // Windows
    "Segoe UI",
    // Android
    Roboto,
    // Basic web fallback
    "Helvetica Neue", Arial,
    // Linux
    "Noto Sans",
    "Liberation Sans",
    // Sans serif fallback
    sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: .20px;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.5px;
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    z-index: 99;
  }


.chroma {
    display: block;
    margin-top: .5rem;
    margin-bottom: .50rem;
    overflow: auto;
    font-size: 0.875em;
}
