@charset "utf-8";

/* circular: an individual ad */
.circular {
  .circular-header {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .circular-title {
    font-weight: bold;
    text-align: center;
  }
  .circular-description {
    text-align: center;
  }
}
