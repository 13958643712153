/* Cards */

//.wsg-card-box {
//  background-clip: border-box;
//  border-radius: 1rem;
//  border: 0.5px solid #eee!important;
//  background: #ffffff;
//  box-shadow: 0 2px 4px rgba(70, 70, 70, 0.5);
//  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
//  &:hover {
//    transition: all 0.5s ease-out;
//    box-shadow: 0px 3px 5px rgba(38, 38, 38, 0.2);
//    border: 1px solid rgb(224, 224, 224);
//    background-color: white;
//    transform: scale(1.02);
//    transform-origin: 50% 50%;
//  }
//}
//
//.wsg-card-title {
//  color: black;
//  text-decoration: none;
//  &:hover, :focus {
//    color: black;
//    text-decoration: none;
//  }
//}
//
//
//.wsg-card-footer {
//  width: 100%;
//  border-top: 1px solid #e6e6e6;
//  border:none;
//  background-color: #f6f6f6;
//  border-radius: 0rem 0rem 0.75rem 0.75rem;
//  padding:1.25rem;
//    &:last-child {
//    width: 100%;
//    border:none;
//    border-top: 1px solid #e6e6e6;
//    background-color: #f6f6f6;
//    border-radius: 0rem 0rem 0.75rem 0.75rem;
//  }
//}

