// Sidebar

// based on https://snook.ca/archives/html_and_css/icons-and-type
.wsg-docs-nav-menu-icon {
    display: inline-block;
    vertical-align: middle;
    position:relative;
    top:-.1em;
  }
  
  // Left side navigation
  //
  
  .wsg-sidebar {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, arial, sans-serif;
    font-size: 16px;
    opacity: 0.95;
    position: -webkit-sticky;
    position: sticky;
    top: 6.375rem; // 102/16 = 6.375
    z-index: 1000;
    overflow-y: auto;
    order: 0;
    background-color: #e5e5e5; 
    //background-color: #f5f2f9;
    border-bottom: 1px solid rgba(0,0,0,.1);
  
    .wsg-toc-category {
      padding-top: 0.5em;
    }
    .wsg-toc-category-link {
      padding-top: 1em;
      font-weight: 700;
    }
    .wsg-toc-group-link {
      padding-left: 0.5em;
    }
    .wsg-toc-doc-link {
      padding-left: 1em;
      font-size: 0.8em;
    }
    .wsg-toc-groups {
      display: none;
    }
    .is-active-category {
      .wsg-toc-groups {
        display: block;
      }
    }
  
  
    @include media-breakpoint-up(md) {
      & {
        top: 4rem;
        max-height: calc(100vh - 4rem);
        border-right: 1px solid rgba(0,0,0,.1);
      }
  
    }
  
    @include media-breakpoint-up(xl) {
      & {
        max-width: 320px;
      }
    }
  }
  
  .wsg-links {
    padding-top: 1rem;
    padding-bottom: 1rem;
  
    @include media-breakpoint-up(md) {
      & {
        display: block !important;
      }
    }
  }
  
  
  .wsg-search {
    position: relative; // To contain the Algolia search
    padding: 1rem 15px;
    margin-right: -15px;
    margin-left: -15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
  }
  
  .wsg-search-docs-toggle {
    line-height: 1;
    color: $gray-900;
  }
  
  .wsg-sidenav {
    display: none;
  }
  
  .wsg-toc-link {
    display: block;
    padding: .25rem 1.5rem;
    font-weight: 500;
    color: rgba(0,0,0,.65);
  
    &:hover {
      color: rgba(0,0,0,.85);
      text-decoration: none;
    }
  }
  
  .wsg-toc-item {
    &.active {
      margin-bottom: 1rem;
  
      &:not(:first-child) {
        margin-top: 1rem;
      }
  
      > .wsg-toc-link {
        color: rgba(0,0,0,.85);
  
        &:hover {
          background-color: transparent;
        }
      }
  
      > .wsg-sidenav {
        display: block;
      }
    }
  }
  
  