/* Fonts */

@font-face{
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Regular'), local('JetBrainsMono-Regular'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/eot/JetBrainsMono-Regular.eot') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/ttf/JetBrainsMono-Regular.ttf') format('truetype');
  font-weight: normal; /* 400 */
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Bold'), local('JetBrainsMono-Bold'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/eot/JetBrainsMono-Bold.eot') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Bold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/ttf/JetBrainsMono-Bold.ttf') format('truetype');
  font-weight: bold; /* 700 */
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Italic'), local('JetBrainsMono-Italic'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/eot/JetBrainsMono-Italic.eot') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Italic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Italic.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/ttf/JetBrainsMono-Italic.ttf') format('truetype');
  font-weight: normal; /* 400 */
  font-style: italic;
  font-display: swap;
}

@font-face{
  font-family: 'JetBrains Mono';
  src:
    local('JetBrains Mono Bold Italic'), local('JetBrainsMono-BoldItalic'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/eot/JetBrainsMono-Bold-Italic.eot') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Bold-Italic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold-Italic.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/ttf/JetBrainsMono-Bold-Italic.ttf') format('truetype');
  font-weight: bold; /* 700 */
  font-style: italic;
  font-display: swap;
}

