.chroma {
    margin: 0;
    background-color: #000;
    color: #cccccc;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: visible;
  }

.chroma .ln { min-width: 50px; width: 50px; text-align: right; }

/* Chroma Word Wrap */

.chroma {    white-space: break-spaces;  }

/* Other */

.chroma .x { color: #d75f00 }

/* LineTableTD */

.chroma .lntd { vertical-align: top; padding: 0; margin: 0; border: 0; }

/* LineTable */

.chroma .lntable { border-spacing: 0; padding: 0; margin: 0; border: 0; width: auto; overflow: auto; display: block; }

/* LineHighlight */

.chroma .hl { display: block; width: 100%;background-color: #ffffcc }

/* LineNumbersTable */

.chroma .lnt { margin-right: 0.4em; padding: 0 0.4em 0 0.4em;color: #454545 }

/* LineNumbers */

.chroma .ln { margin-right: 0.4em; padding: 0 0.4em 0 0.4em;color: #8e8d8b; }

/* Keyword */

.chroma .k { color: #cc7833 }

/* KeywordConstant */

.chroma .kc { color: #d75f00 }

/* KeywordDeclaration */

.chroma .kd { color: #5fb8ff }

/* KeywordNamespace */

.chroma .kn { color: #d75f00 }

/* KeywordPseudo */

.chroma .kp { color: #84a732 }

/* KeywordReserved */

.chroma .kr { color: #5fb8ff }

/* KeywordType */

.chroma .kt { color: #af0000 }

/* Name */

.chroma .n { color: #FFC66D }

/* NameBuiltin */

.chroma .nb { color: #5fb8ff }

/* NameBuiltinPseudo */

.chroma .bp { color: #5fb8ff }
/* NameAttribute */ 

.chroma .na { color: #d75f00  }

/* NameClass */

.chroma .nc { color: #5fb8ff }

/* NameConstant */

.chroma .no { color: #d75f00 }

/* NameDecorator */

.chroma .nd { color: #5fb8ff }

/* NameEntity */

.chroma .ni { color: #d75f00 }

/* NameException */

.chroma .ne { color: #af8700 }

/* NameFunction */

.chroma .nf { color: #5fb8ff }

/* NameTag */

.chroma .nt { color: #0194d3 }

/* NameVariable */

.chroma .nv { color: #5fb8ff }


.chroma .p { color: #535353; }

/* LiteralString */

.chroma .s { color: #FFC66D }

/* LiteralStringAffix */

.chroma .sa { color: #32b0b0 }

/* LiteralStringBacktick */

.chroma .sb { color: #4e4e4e }

/* LiteralStringChar */

.chroma .sc { color: #32b0b0 }

/* LiteralStringDelimiter */

.chroma .dl { color: #32b0b0 }

/* LiteralStringDoc */

.chroma .sd { color: #32b0b0 }

/* LiteralStringDouble */

.chroma .s2 { color: #32b0b0 }

/* LiteralStringEscape */

.chroma .se { color: #af0000 }

/* LiteralStringHeredoc */

.chroma .sh { color: #32b0b0 }

/* LiteralStringInterpol */

.chroma .si { color: #32b0b0 }

/* LiteralStringOther */

.chroma .sx { color: #32b0b0 }

/* LiteralStringRegex */

.chroma .sr { color: #af0000 }

/* LiteralStringSingle */

.chroma .s1 { color: #AA8FC2 }

/* LiteralStringSymbol */

.chroma .ss { color: #FFC66D }

/* LiteralNumber */

.chroma .m { color: #32b0b0 }

/* LiteralNumberBin */

.chroma .mb { color: #32b0b0 }

/* LiteralNumberFloat */

.chroma .mf { color: #32b0b0 }

/* LiteralNumberHex */

.chroma .mh { color: #32b0b0 }

/* LiteralNumberInteger */

.chroma .mi { color: #32b0b0 }

/* LiteralNumberIntegerLong */

.chroma .il { color: #32b0b0 }

/* LiteralNumberOct */

.chroma .mo { color: #32b0b0 }

/* OperatorWord */

.chroma .ow { color: #5f8700 }

/* Comment */

.chroma .c { color: #4e4e4e }

/* CommentHashbang */

.chroma .ch { color: #4e4e4e }

/* CommentMultiline */

.chroma .cm { color: #4e4e4e }

/* CommentSingle */

.chroma .c1 { color: #629755 }

/* CommentSpecial */

.chroma .cs { color: #5f8700 }

/* CommentPreproc */

.chroma .cp { color: #5f8700 }

/* CommentPreprocFile */

.chroma .cpf { color: #5f8700 }

/* GenericDeleted */

.chroma .gd { color: #af0000 }

/* GenericEmph */

.chroma .ge { font-style: italic }

/* GenericError */

.chroma .gr { color: #af0000; font-weight: bold }

/* GenericHeading */

.chroma .gh { color: #d75f00 }

/* GenericInserted */

.chroma .gi { color: #5f8700 }

/* GenericStrong */

.chroma .gs { font-weight: bold }

/* GenericSubheading */

.chroma .gu { color: #5fb8ff }

