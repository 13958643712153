@charset "utf-8";

/* circular-item: an individual ad item */
.circular-item-standard {
  text-align: center;

  .circular-item-body {
    padding: 5px;
  }

  .circular-item-heading,
  .circular-item-description,
  .circular-item-details,
  .circular-item-expiration-date,
  .circular-item-sale-dates,
  .circular-item-more-info {
    font-size: 0.625rem; // 10/16=0.625
  }

  .circular-item-image {
    min-height: 0.01px; // fixes height on IE11
  }

  .circular-item-recipe {
    font-size: 0.75rem; // 12/16=0.75
  }

  .circular-item-info-trigger {
    margin-bottom: 0;
  }

  .circular-item-title {
    font-size: 1.125rem; // 18/16=1.125
  }

  .circular-item-heading,
  .circular-item-title,
  .price,
  .circular-item-description,
  .circular-item-details,
  .circular-item-expiration-date,
  .circular-item-sale-dates,
  .circular-item-more-info,
  .circular-item-recipe {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .circular-item-recipe {
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
  }

  &.circular-item-price-top {
    .circular-item-body {
      position: relative;
      padding-top: calc(#{$spacer} + 48px);
    }
    .price {
      position: absolute;
      top: calc(#{$spacer} / 2);
      text-align: center;
      width: 100%;
    }
  }

  &.circular-item-price-bottom {
    .circular-item-body {
      position: relative;
      padding-bottom: calc(#{$spacer} + 48px);
    }
    .price {
      position: absolute;
      bottom: calc(#{$spacer} / 2);
      text-align: center;
      width: 100%;
    }
  }

  // iPad & Desktop
  @include media-breakpoint-up(sm) {
    .circular-item-body {
      padding: 10px;
    }

    .circular-item-title {
      font-size: 1.75rem; // 28/16=1.75
    }

    .circular-item-heading,
    .circular-item-description,
    .circular-item-details,
    .circular-item-expiration-date,
    .circular-item-sale-dates,
    .circular-item-more-info {
      font-size: 0.75rem; // 12/16=0.75
    }

    .circular-item-recipe {
      font-size: 1rem;
    }

    .circular-item-heading,
    .circular-item-title,
    .price,
    .circular-item-description,
    .circular-item-details,
    .circular-item-expiration-date,
    .circular-item-sale-dates,
    .circular-item-more-info,
    .circular-item-recipe {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .circular-item-recipe {
      padding-left: 10px;
      padding-right: 10px;
    }

  }

}
