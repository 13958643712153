@charset "utf-8";

.tag {
  position:relative;
  padding: 0.25em 0.4em 0.25em 0.8em;
  font-size: 75%;
  margin: 1px;
  color: #fff;
  display: inline-block;
  background-color: $secondary;
  &::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    border-width:0.6em 0.6em 0 0;
    border-style:solid;
    border-color:#fff transparent transparent #fff ;
  }
  &::after {
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    border-width:0 0.6em 0.6em 0;
    border-style:solid;
    border-color: transparent transparent #fff #fff;
  }
  [data-tag-search-remove-tag] {
    cursor: pointer;
  }
}

.tag-group {
  margin-bottom: calc(#{$spacer}/2);
  .tag {
    margin-left: calc(#{$spacer}/2);
    margin-bottom: calc(#{$spacer}/2);
  }
}
