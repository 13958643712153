@charset "utf-8";

.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  opacity: 0.95;
  background-color: $toolbar-bg;
  padding: $toolbar-padding-y 0 $toolbar-padding-y $toolbar-padding-x;
  min-height: 54px;
  margin-right: -$spacer + 0.0625rem; // 1px/16 = 0.0625rem
  margin-left: -$spacer;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;

  .input-group {
    width: auto;
  }

  .toolbar-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin: 0;
    margin-right: $toolbar-spacer;
    height: 38px;

    .btn,
    .form-control,
    .custom-select,
    .input-group-text,
    .page-link {
      border-color: $toolbar-border-color;
    }
  }

  > .toolbar-group:first-child {
    margin-left: 0;
  }

  .toolbar-text-item {
    padding-top: $btn-padding-y;
    padding-bottom: $btn-padding-y;
  }

}

// The toolbar-detached class is for docs & examples, not intended for production use
.toolbar-detached {
  .toolbar {
    position: relative;
    top: 0;
  }
}

// Bug: Edge forces dropdown menus under other items because it doesn't understand position: sticky
// the following fixes that.
@supports (-ms-ime-align: auto) {
  .toolbar .circular-toolbar {
    position: relative;
  }
}
