// Max Width

.max-width-320 {
  max-width: 320px;
}

.max-width-360 {
  max-width: 360px;
}

.max-width-375 {
  max-width: 375px;
}

.max-width-450 {
  max-width: 450px;
}

// Body Content
.max-width-1140 {
  max-width: 1140px;
}

.max-width-1440 {
  max-width: 1440px;
}

.max-width-1600 {
  max-width: 1600px;
}

