
// Font Families
$wsg-sans-serif: 'Montserrat', $font-family-sans-serif;
$wsg-serif: 'Libre Baskerville', Georgia, serif;
$wsg-monospace: 'JetBrains Mono', $font-family-monospace;


// Colors
$webstop-blue: #0194d3;
$wsg-primary: darken(#0194d3, 10%);
$wsg-blue-alt:#5fb8ff;
$wsg-gray:#f6f6f6;
$wsg-gray-100:#fafafa;
$wsg-light:#fff;
$wsg-yellow:#fddd26;
$wsg-dark:#01000b;


//$enable-gradients:false;
//$spacer:1.5rem;
//$border-width:1px;
//$btn-border-radius:.25rem;
//$enable-shadows:true;
