@charset "utf-8";

@import
  'variables_bootstrap',
  '../../../node_modules/bootstrap/scss/bootstrap',
  '../../../scss/core-styles',

  'variables',
  'layout_customizations',
  'icons',
  'table_of_contents',
  'admin_layout',
  'examples_highlights',
  'fonts',
  'buttons',
  'scrollbar',
  'toolbar',
  'cards',
  'heading',
  'tables',
  'typography',
  'syntax_defaults',
  'syntax_customizations'
;

.test-compile {
  background: #0a53be;
}
