//.public-header  { grid-area: public-header;  overflow: visible; }
//.public-sidecar { grid-area: public-sidecar; overflow-x: auto; display: block; }
//.public-sidenav { grid-area: public-sidenav; overflow-x: auto; display: block; }
//.public-page    { grid-area: public-page;    overflow-x: auto; display: block; }
//.public-sidebar { grid-area: public-sidebar; overflow-x: auto; display: block; }

.public {
  grid-auto-columns: 0;
  grid-auto-rows: 0;
  grid-template-columns: auto;
  display: grid;
  grid-template-areas:
    "public-header"
    "public-page";
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;

  // Grid Height Control
  // Note, the Heights section lower in the document also affects heights.
  grid-template-rows: $public-header-height auto; // XS/Mobile
  @include media-breakpoint-up(sm)  { grid-template-rows: $public-header-sm-height  auto; }
  @include media-breakpoint-up(md)  { grid-template-rows: $public-header-md-height  auto; }
  @include media-breakpoint-up(lg)  { grid-template-rows: $public-header-lg-height  auto; }
  @include media-breakpoint-up(xl)  { grid-template-rows: $public-header-xl-height  auto; }
  @include media-breakpoint-up(xxl) { grid-template-rows: $public-header-xxl-height auto; }

  .public-header  { grid-area: public-header;  overflow: visible; }
  .public-sidecar { grid-area: public-sidecar; overflow-x: auto; display: none; }
  .public-sidenav { grid-area: public-sidenav; overflow-x: auto; display: none; }
  .public-page    { grid-area: public-page;    overflow-x: auto; display: block; }
  .public-sidebar { grid-area: public-sidebar; overflow-x: auto; display: none; }

  .public-sidenav-grid-btn      { display: none; }
  .public-sidebar-grid-btn      { display: none; }
  .public-sidebar-offcanvas-btn { display: none; }


  //.public-header  { grid-area: public-header;  overflow: visible; }
  //.public-sidecar { grid-area: public-sidecar; overflow-x: auto; display: block; }
  //.public-sidenav { grid-area: public-sidenav; overflow-x: auto; display: block; }
  //.public-page    { grid-area: public-page;    overflow-x: auto; display: block; }
  //.public-sidebar { grid-area: public-sidebar; overflow-x: auto; display: block; }


  //.public-footer  { grid-area: public-footer;  overflow: visible; }

 // @include media-breakpoint-up(lg) { }

  // Layout with Sidecar
  &.public-with-sidecar {
    @include media-breakpoint-up(sm) {
      grid-template-columns: $public-sidecar-width auto;
      grid-template-areas:
        "public-header  public-header"
        "public-sidecar public-page";
      .public-sidecar { display: block; }
    }
  }

  // Layout with Sidenav
  &.public-with-sidenav {
    .public-sidenav-offcanvas-btn { display: block; }
    @include media-breakpoint-up(xl) {
      grid-template-columns: $public-sidenav-width auto;
      grid-template-areas:
        "public-header  public-header"
        "public-sidenav public-page";
      .public-sidenav { display: block; }
      .public-sidenav-grid-btn { display: block; }
      .public-sidenav-offcanvas-btn { display: none; }
    }
  }

  // Layout with Sidebar
  &.public-with-sidebar {
    .public-sidebar-offcanvas-btn { display: block; }
    @include media-breakpoint-up(xl) {
      grid-template-columns: auto $public-sidebar-width;
      grid-template-areas:
        "public-header public-header"
        "public-page   public-sidebar";
      .public-sidebar { display: block; }
      .public-sidebar-grid-btn      { display: block; }
      .public-sidebar-offcanvas-btn { display: none; }
    }
    &.public-hide-sidebar {
      grid-template-columns: auto;
      grid-template-areas:
          "public-header"
          "public-page";
      .public-sidebar { display: none; width: 0; height: 0; }
    }
  }


  // Layout with Sidecar & Sidenav
  &.public-with-sidecar.public-with-sidenav {
    @include media-breakpoint-up(xl) {
      grid-template-columns: $public-sidecar-width $public-sidenav-width auto;
      grid-template-areas:
        "public-header  public-header public-header"
        "public-sidecar public-sidenav public-page";
      .public-sidecar { display: block; }
      .public-sidenav { display: block; }
    }
  }

  // Layout with Sidecar & Sidebar
  &.public-with-sidecar.public-with-sidebar {
    @include media-breakpoint-up(xl) {
      grid-auto-columns: 0;
      grid-auto-rows: 0;
      grid-template-columns: $public-sidecar-width auto $public-sidebar-width;
      grid-template-areas:
        "public-header  public-header public-header"
        "public-sidecar public-page   public-sidebar";
      .public-sidecar { display: block; }
      .public-sidebar { display: block; }
    }
  }


  // Layout with Sidenav & Sidebar
  &.public-with-sidenav.public-with-sidebar {
    .public-sidenav-offcanvas-btn { display: block; }
    .public-sidebar-offcanvas-btn { display: block; }
    @include media-breakpoint-only(xl)  {
      grid-auto-columns: 0;
      grid-auto-rows: 0;
      grid-template-columns: auto $public-sidebar-width;
      grid-template-areas:
        "public-header public-header"
        "public-page   public-sidebar";
      .public-sidebar { display: block; }
      .public-sidenav { display: none; width: 0; height: 0; }
      .public-sidebar-offcanvas-btn { display: none;  }
      .public-sidebar-grid-btn      { display: block; }

      &.public-hide-sidebar {
        grid-template-columns: auto;
        grid-template-areas:
          "public-header"
          "public-page";
        .public-sidebar { display: none; width: 0; height: 0; }
      }
    }

    @include media-breakpoint-up(xxl) {
      grid-auto-rows: 0;
      grid-template-columns: $public-sidenav-width auto $public-sidebar-width;
      grid-template-areas:
        "public-header  public-header public-header"
        "public-sidenav public-page   public-sidebar";
      .public-sidebar { display: block; }
      .public-sidenav { display: block; }
      .public-sidenav-offcanvas-btn { display: none;  }
      .public-sidenav-grid-btn      { display: block; }

      &.public-hide-sidebar {
        grid-template-columns: $public-sidenav-width auto;
        grid-template-areas:
          "public-header public-header"
          "public-sidenav public-page";
        .public-sidebar { display: none; width: 0; height: 0; }
      }
    }

    &.public-hide-sidenav {
      grid-template-columns: auto $public-sidebar-width;
      grid-template-areas:
          "public-header public-header"
          "public-page   public-sidebar";
      .public-sidenav { display: none; width: 0; height: 0; }
    }

    &.public-hide-sidenav.public-hide-sidebar {
      grid-template-columns: auto;
      grid-template-areas:
        "public-header"
        "public-page";
    }

  }

  // Layout with Sidecar, Sidenav, & Sidebar
  &.public-with-sidecar.public-with-sidenav.public-with-sidebar {

    @include media-breakpoint-up(xl)  {
      grid-template-columns: auto $public-sidebar-width;
      grid-template-areas:
        "public-header public-header"
        "public-page   public-sidebar";
      .public-sidebar { display: block; }
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: $public-sidecar-width $public-sidenav-width auto $public-sidebar-width;
      grid-template-areas:
        "public-header  public-header  public-header public-header"
        "public-sidecar public-sidenav public-page   public-sidebar";
      .public-sidecar { display: block; }
      .public-sidenav { display: block; }
    }
    @include media-breakpoint-up(xxl) {  }

  }

  // Borders
  &.public-borders {
    .public-header  {
      border-bottom: $public-header-border-size solid $public-header-border-color;
      background-color: $public-header-bg-color;
    }
    .public-sidecar {
      border-right: $public-sidecar-border-size solid $public-sidecar-border-color;
      background-color: $public-sidecar-bg-color;
    }
    .public-sidenav {
      border-right: $public-sidenav-border-size solid $public-sidenav-border-color;
      background-color: $public-sidenav-bg-color;
    }
    .public-sidebar {
      border-left: $public-sidebar-border-size solid $public-sidebar-border-color;
      background-color: $public-sidebar-bg-color;
    }
    .public-footer {
      border-top: $public-footer-border-size solid $public-footer-border-color;
      background-color: $public-footer-bg-color;
    }
  }

  // Heights
  // Note, the Grid Height Control section near the top of the document also affects heights.
  .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-height});}
  .public-header { height: $public-header-height; }
  @include media-breakpoint-up(sm)  {
    .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-sm-height});}
    .public-header { height: $public-header-sm-height; }
  }
  @include media-breakpoint-up(md)  {
    .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-md-height});}
    .public-header { height: $public-header-md-height; }
  }
  @include media-breakpoint-up(lg)  {
    .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-lg-height});}
    .public-header { height: $public-header-lg-height; }
  }
  @include media-breakpoint-up(xl)  {
    .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-xl-height});}
    .public-header { height: $public-header-xl-height; }
  }
  @include media-breakpoint-up(xxl) {
    .public-sidecar, .public-sidenav, .public-page, .public-sidebar { height: calc(100vh - #{$public-header-xxl-height});}
    .public-header { height: $public-header-xxl-height; }
  }


  // Padding
  .public-sidenav {
    padding: $public-sidenav-spacer-y $public-sidenav-spacer-x;
  }
  .public-content {
    padding: 0 $public-content-spacer-x $public-content-spacer-y $public-content-spacer-x;
  }
  .public-sidebar {
    padding: $public-sidebar-spacer-y $public-sidebar-spacer-x;
  }
  .public-sidecar {
    padding: $public-sidecar-spacer-y $public-sidecar-spacer-x;
  }
  .public-footer {
    padding: $public-footer-spacer-y $public-footer-spacer-x;
  }

  // Colors
  .public-sidecar {
    color: $public-sidecar-color;
  }

  .public-sidecar-item {
    color: $public-sidecar-color;
  }







  // Special Page Views
  .public-table-view,
  .public-list-view {
    padding: 0;
  }

  .public-table-view {
    overflow: visible;
  }

  // consider using Bootstrap utility .p-0 instead
  .public-page-flush {
    padding: 0;
  }

}


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

//@each $breakpoint in map-keys($grid-breakpoints) {
//  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//
//  @include media-breakpoint-down($breakpoint) {
//    .table-responsive#{$infix} {
//      overflow-x: auto;
//      -webkit-overflow-scrolling: touch;
//    }
//  }
//}


//@include media-breakpoint-down(sm) {
//
//}

//$grid-breakpoints: (
//  xs: 0,
//  sm: 576px,
//  md: 768px,
//  lg: 992px,
//  xl: 1200px,
//  xxl: 1400px
//) !default;
