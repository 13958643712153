// Boxes



//.box-shadow {
//  // box-shadow: 5px 5px 10px 2px #ccc;
//  box-shadow: 5px 5px 10px #d9d6c1;
//}

.box-center {
  @extend .mx-auto;
}

.box-stackable {
  margin-bottom: $grid-gutter-width; // 20/16=1.25
}

//.box-standard {
//  border-color: #aaa;
//  //transition: 5s;
//  box-shadow: 5px 5px 10px #d9d6c1;
//  margin-bottom: $grid-gutter-width; // 20/16=1.25
//}


