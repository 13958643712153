@charset "utf-8";

/* circular-item: an individual image item */
.circular-item-standard-promotion {
  text-align: center;

  .circular-item-body {
    padding: 5px;
  }

  .circular-item-description{
    font-size: 0.625rem; // 10/16=0.625
  }

  .circular-item-title,
  .circular-item-description {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .circular-item-title{
    font-size: 1.125rem; // 18/16=1.125
  }

  .circular-item-footer {
    padding-left: 10px;
    padding-right: 10px;
    .circular-item-button {
      display: block;
    }
  }

  .modal {
    text-align: left;
  }

  // iPad & Desktop styles
  @include media-breakpoint-up(sm) {
    .circular-item-body {
      padding: 10px;
    }

    .circular-item-description{
      font-size: 0.75rem; // 12/16=0.75
    }

    .circular-item-title,
    .circular-item-description {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .circular-item-footer {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}
