@charset "utf-8";

.circular-toolbar {
  .circular-toolbar-page-title {
    line-height: 1rem;
  }

  .search-from-search-text {
    max-width: 125px;
    @include media-breakpoint-up(xl) {
      max-width: 200px;
    }
  }

}
