@charset "utf-8";

.circular-item-banner {
  max-width: none;
  display: block; // fixes height on IE11


  // iPad & Desktop
  @include media-breakpoint-up(sm) {
    &.card {
      margin-right: 0;
    }
  }
}
