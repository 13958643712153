/* SCROLLBAR*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; 
  border-radius:0.75rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lighten($webstop-blue, 20%); 
}
/* END SCROLLBAR */