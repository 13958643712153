/* ADMIN LAYOUT */

.admin .admin-sidenav {
  border-right: 0px solid #ccc;
  background-color: $wsg-gray;
}

.admin .admin-sidebar {
  border-left: 0 solid #bacad9;
  background-color: $wsg-gray-100;
}

/* END ADMIN LAYOUT */