// webstop

.webstop-color {
  color: darken($webstop-blue, 10%);
  &:visited {
    color: $webstop-blue;
  }
  &:hover {
    color: darken($webstop-blue, 20%);
  }
}


.bg-webstop{
  background-color: $webstop-blue;
}

.webstop-sans-serif {
  font-family: "Montserrat", $font-family-sans-serif;
}

.webstop-serif {
  font-family: "Libre Baskerville", Baskerville, "Baskerville Old Face", Georgia, serif;
}

.webstop-logo {

}

.webstop-logo-alt {

}
