// Page Break

@media all {
  .page-break:empty { 
    display: none; 
  }
}

@media print {
  .page-break { 
    clear: both;
    display: block; 
    break-after: always; 
  }
}
