@charset "utf-8";

.is-select {
  .check-unselect-text {
    display: none;
  }
}
.is-unselect {
  .check-select-text {
    display: none;
  }
}
