// Theming & Branding variables
$theme-primary: #d9d6c1 !default;
$theme-bg: lighten($theme-primary, 15%) !default;
$theme-active-bg: lighten($theme-primary, 7.5%) !default;
$body-bg:  $theme-bg !default;

// Layout
// ------

// Admin Layout
// Admin Layout Sizes
$admin-sidecar-width:        90px !default;
$admin-sidenav-width:        320px !default;
$admin-sidebar-width:        320px !default;
$admin-header-height:        60px !default;
$admin-footer-height:        40px !default;
$admin-spacer-y:             $spacer !default;
$admin-spacer-x:             $spacer !default;

// Admin Layout Colors
$admin-sidecar-color:        #fff !default;
$admin-sidecar-bg-color:     $primary !default;
$admin-sidecar-border-color: $primary !default;
$admin-sidecar-border-size:  0 !default;
$admin-sidenav-bg-color:     #e9ecef !default;
$admin-sidenav-border-color: #ccc !default;
$admin-sidenav-border-size:  1px !default;
$admin-sidebar-bg-color:     #dbe3eb !default;
$admin-sidebar-border-color: darken($admin-sidebar-bg-color, 10%) !default;
$admin-sidebar-border-size:  1px !default;
$admin-footer-bg-color:      #e9ecef !default;
$admin-footer-border-color:  #ccc !default;
$admin-footer-border-size:   1px !default;

// Public Layout
// Public Layout Sizes
$public-header-height:        60px !default;
$public-header-sm-height:     $public-header-height !default;
$public-header-md-height:     $public-header-sm-height !default;
$public-header-lg-height:     $public-header-md-height !default;
$public-header-xl-height:     $public-header-lg-height !default;
$public-header-xxl-height:    $public-header-xl-height !default;
$public-sidecar-width:        90px !default;
$public-sidenav-width:        320px !default;
$public-sidebar-width:        320px !default;
$public-spacer-y:             $spacer !default;
$public-spacer-x:             $spacer !default;
$public-header-spacer-y:      0 !default;
$public-header-spacer-x:      0 !default;
$public-sidecar-spacer-y:     $public-spacer-y !default;
$public-sidecar-spacer-x:     0 !default;
$public-sidenav-spacer-y:     0 !default;
$public-sidenav-spacer-x:     0 !default;
$public-content-spacer-y:     $public-spacer-y !default;
$public-content-spacer-x:     $public-spacer-x !default;
$public-sidebar-spacer-y:     0 !default;
$public-sidebar-spacer-x:     0 !default;
$public-footer-spacer-y:      $public-spacer-y !default;
$public-footer-spacer-x:      $public-spacer-x !default;


// Public Layout Colors
$public-header-bg-color:      transparent !default;
$public-header-border-color:  #ccc !default;
$public-header-border-size:   1px !default;
$public-sidecar-color:        $white !default;
$public-sidecar-bg-color:     $primary !default;
$public-sidecar-border-color: $primary !default;
$public-sidecar-border-size:  0 !default;
$public-sidenav-bg-color:     transparent !default;
$public-sidenav-border-color: #ccc !default;
$public-sidenav-border-size:  1px !default;
$public-sidebar-bg-color:     transparent !default;
$public-sidebar-border-color: #ccc !default;
$public-sidebar-border-size:  1px !default;
$public-footer-bg-color:      transparent !default;
$public-footer-border-color:  #ccc !default;
$public-footer-border-size:   1px !default;

// Utilities
// ---------

$readable: 34.25rem + (1.25rem * 2) !default;

// Components
// ----------

// Card Deck
$card-deck-margin: $card-group-margin !default;


// Toolbar
$toolbar-spacer:       $spacer !default;
$toolbar-padding-x:    $toolbar-spacer !default;
$toolbar-padding-y:    calc(#{$toolbar-spacer}/2) !default;
$toolbar-bg:           $theme-primary !default;
$toolbar-border-color: darken($toolbar-bg, 15%) !default;
//$toolbar-min-height: 58px !default;

// Grocery Apps
// ===========

// Circulars
$circular-max-column-width:    375px !default;
$circular-min-column-width:    160px !default;
$circular-sm-max-column-width: 375px !default;
$circular-sm-min-column-width: 265px !default;

// Shopping List
$shopping-list-column-width: 250px !default;

// Stores
$store-max-column-width: 100%  !default;
$store-min-column-width: 290px !default;

// ---

// Bootstrap
$bootstrap-purple: #563d7c;
$table-bg: #fff !default;

// Webstop
$webstop-blue: #0194d3;
$webstop-sans-serif: Montserrat, $font-family-sans-serif;
$webstop-serif: "Libre Baskerville", Baskerville, "Baskerville Old Face", Georgia, serif;
$webstop-monospace: "JetBrains Mono", $font-family-monospace;

